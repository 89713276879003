import React, { Fragment } from 'react';
import '../Menu/Menu.css';

const BtnEliminar = ({ click, customClass }) => {
    return (<Fragment>
        <button
            type="button"
            onClick={click}
            className={customClass ? customClass : "botonEliminar"}
            data-toggle="tooltip"
            data-placement="top"
            data-html2canvas-ignore>
            <i className="fas fa-times"></i>
        </button>
    </Fragment>)
}
export default BtnEliminar;