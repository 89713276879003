import React from "react";
import BtnEliminar from "../BtnEliminar/BtnEliminar";
import Swal from "sweetalert2";

const Ingrediente = ({ data, deleteIngrediente, btnEliminarIngrediente }) => {

    const ingrediente = {
        display:"flex",
        flexDirection:"column",
        flex: '1 0 22%', 
        maxWidth: '22%',
        margin: "0px",
        padding: "0px",
        textAlign: "center",
    };

    const estilosImg = {
        borderRadius: "10%"
    }

    const alertDeleteIngrediente = () => {
        deleteIngrediente(data.idIngrediente);
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Ingrediente eliminado',
            showConfirmButton: false,
            timer: 1500
        })
    }

    return (
        <div  style={ingrediente}>
            {btnEliminarIngrediente ? <BtnEliminar customClass={"botonEliminarIngrediente"} click={alertDeleteIngrediente}/> : null}
                <img
                    id="img"
                    src={data.src}
                    alt="Añada el ingrediente"
                    width="100%"
                    height="60%"
                    style={estilosImg}
                />
            <p className="text-uppercase" style={{
                    fontSize: "10px",
                    margin: "0px"

                }}>
                    {data.nombre}
                </p>
            </div>
    );
};

export default Ingrediente;
