import React from "react";
import Swal from "sweetalert2";
import BtnEliminar from "../BtnEliminar/BtnEliminar";
const maxCharPalabra = 12;
const maxCharNombreIngrediente = 30;
const NuevoIngrediente = ({ addIngrediente, canAddAnother, ingrediente, estadoIngrediente, setIngrediente, cleanIngrediente }) => {


    const estiloInputPicto = {
        width: "80%",
        height: "100px",
        textAlign: "center",
        resize: "none"
    }


    const onSubmit = e => {
        e.preventDefault();
        if (ingrediente.nombre.length <= maxCharNombreIngrediente) {
            let palabras = ingrediente.nombre.split(' ')
            for (let i = 0; i < palabras.length; i++) {
                if (palabras[i].length > maxCharPalabra) {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "Las palabras ingresadas no deben exceder los " + maxCharPalabra + " caracteres",
                        showConfirmButton: true,
                    })
                    return
                }
            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "El nombre del ingrediente no debe exceder los " + maxCharNombreIngrediente + " caracteres",
                showConfirmButton: true,
            })
            return
        }
        addIngrediente(ingrediente);
        setIngrediente(estadoIngrediente);
        cleanIngrediente()
    }

    const onChange = e => {
        setIngrediente({
            ...ingrediente, [e.target.name]: e.target.value
        });
    };
    return (
        <form onSubmit={onSubmit} className="text-center">
            <div className="row justify-content-center">
                <div className="col-sm-12 col-md-8">
                    <div className="form-group">
                        {ingrediente.src == "" ?
                            <textarea
                                id="inputSrcIngrediente"
                                type="text"
                                name="src"
                                className="form-control"
                                placeholder="(Pictograma del ingrediente)"
                                onChange={onChange}
                                required={true}
                                style={estiloInputPicto}
                                value={ingrediente.src}
                            /> :
                            <div className="text-center">
                                <BtnEliminar click={() => cleanIngrediente()} />
                                <img
                                    src={ingrediente.src}
                                    alt="Añada el ingrediente"
                                    width="156"
                                    height="156"
                                />
                            </div>}
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            name="nombre"
                            maxLength={maxCharNombreIngrediente}
                            className="form-control text-dark"
                            placeholder="Nombre"
                            onChange={onChange}
                            required={true}
                            value={ingrediente.nombre}
                            style={{ textAlign: "center" }}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group text-center">
                {canAddAnother ?
                    <button className="primary-button" type="submit">Agregar
                    </button> : null}
            </div>
        </form>
    );
};

export default NuevoIngrediente;
