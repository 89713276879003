import React, { Component } from 'react';
import '../Resources/custom.css';
import Swal from 'sweetalert2';

const initState = {
    email: "",
    preguntaseguridad: 0,
    respuestaseguridad: "",
    contrasena: "",
    confirmPass: "",
    errEmail: "",
    errPassword: "",
    errConfirmPass: "",
    errSec_quest: "",
    errAnsw: ""
};

export class Registro extends Component {
    static displayName = Registro.name;

    constructor(props) {
        super(props);
        this.state = initState;

    };

    handleChange = e => {
        const { target: { name, value } } = e;
        this.setState({ [name]: value });
}

    handleValidation = () => {
        const { email, contrasena, confirmPass, preguntaseguridad, respuestaseguridad } = this.state;
        let errEmail, errPassword, errConfirmPass, errSec_quest, errAnsw = "";
        if (email.length < 6 || !(email.includes("@"))) {
            errEmail = "El email es incorrecto.";
        }
        if (contrasena.length < 6) {
            errPassword = "La contraseña es demasiado corta.";
        }
        if (!(contrasena === confirmPass)) {
            errConfirmPass = "Las contraseñas no son iguales.";
        }
        if (preguntaseguridad === 0) {
            errSec_quest = "Debe elegir una pregunta.";
        }
        if (!respuestaseguridad) {
            errAnsw = "Aún no escribió su respuesta.";
        }
        if (errEmail || errPassword || errConfirmPass || errSec_quest || errAnsw) {
            this.setState({ errEmail, errPassword, errConfirmPass, errSec_quest, errAnsw });
            return false;
        }
        return true;
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.handleValidation()) {
            fetch("/register", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({ email: this.state.email, contrasena: this.state.contrasena, preguntaseguridad: parseInt(this.state.preguntaseguridad), respuestaseguridad: this.state.respuestaseguridad.toLowerCase().replace(/ /g,"") })
            }).then(response => {
                if (response.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Registrado correctamente',
                        showConfirmButton: false,
                        timer: 2000                        
                    })
                    this.props.history.push("/validar");
                } else if (response.status === 400) {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Email en uso, intente con otro',
                        showConfirmButton: true,
                        timer: 3000
                    })
                };
            });
            this.setState(initState);
        };
    };

    render() {
        return (
            <div class="container-fluid container vh-100 text-center">
                <h3 class="title">Registrarse</h3>
                <div id="card" className="grow container p-3 my-4">                    
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <div className="form">
                                <form action="" method="post" name="registro">
                                    <div className="form-group">
                                        <input type="email" name="email" className="form-control" id="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} />
                                        <span className="d-block text-center text-danger mt-1">{this.state.errEmail}</span>
                                    </div>
                                    <div className="form-group">
                                        <select type="sec_quest" name="preguntaseguridad" className="custom-select" placeholder="Pregunta secreta" onChange={this.handleChange} style={{ border: "1px solid #064EB4"}}>
                                                <option disabled selected defaultValue>-Pregunta de seguridad-</option>
                                                <option value={1} >Nombre de tu primera mascota</option>
                                                <option value={2} >Personaje histórico favorito</option>
                                                <option value={3} >Lugar de nacimiento de tu madre</option>
                                                <option value={4} >Personaje de ficción favorito</option>
                                        </select>
                                        <span className="d-block text-center text-danger mt-1">{this.state.errSec_quest}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="answ" name="respuestaseguridad" className="form-control" id="respuestaseguridad" placeholder="Respuesta" value={this.state.respuestaseguridad} onChange={this.handleChange} />
                                        <span className="d-block text-center text-danger mt-1">{this.state.errAnsw}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" name="contrasena" className="form-control" id="contrasena" placeholder="Contraseña" value={this.state.contrasena} onChange={this.handleChange} />
                                        <span className="d-block text-center text-danger mt-1">{this.state.errPassword}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" name="confirmPass" className="form-control" id="confirmPass" placeholder="Confirmar contraseña" value={this.state.confirmPass} onChange={this.handleChange} />
                                        <span className="d-block text-center text-danger mt-1">{this.state.errConfirmPass}</span>
                                    </div>
                                    <div className="text-center">
                                        <button id="boton" type="submit" className="btn btn-primary btn-block" onClick={this.handleSubmit}>Crear cuenta</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}
