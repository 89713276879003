import React, { useState } from 'react';
import { Pic } from './Pic';
import "./BusquedaPic.css";

const BusquedaPic = ({ addpicto,pictograms,setPictograms, parametroBusqueda, setParametroBusqueda }) => {
  const [invalid, setInvalid] = useState("");

  function buscar(e) {
    e.preventDefault();
    setInvalid("");
    if (parametroBusqueda.length > 0) {
      fetch('https://api.arasaac.org/api/pictograms/es/search/' + parametroBusqueda)
        .then(results => {
            if (results.status === 404) {
                setInvalid("No se encontraron pictogramas relacionados a esa busqueda.");
            }
          return results.json();
        })
        .then(data => {
          setPictograms(data);
        })
    }
    else {      
      setPictograms([]);
    }
  }

  function handleInput(e) {
    setParametroBusqueda(e.target.value);
  }

  return (
      <div  className="text-center p-1">
          <h5 className="text-center text-dark">Buscador de alimentos</h5>
      <form id="formBuscar">
        <input className="form-control text-dark text-center" id="input" value={parametroBusqueda} onChange={handleInput} type="text" placeholder="Alimento" />
        <button className="btn btn-primary mt-2 w-100" onClick={buscar}>Buscar</button>
        <span className="d-block text-center text-danger mt-1">{invalid}</span>
      </form>
          <div id="Resultados" style={{ height: '40vh', overflow: 'auto' }}>
              {pictograms?.map((r, index) => <Pic key={index} id={r._id + ""} addpicto={addpicto}/>)}
          </div>
    </div>
  )
}
export default BusquedaPic;
