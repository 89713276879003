import React, { useState, useContext } from 'react';
import { useInputChange } from '../useInputChange'
import userLoginContext from '../userContext';
import '../Registro/Registro.css';
import Swal from 'sweetalert2';
import Cookie from 'js-cookie';

const Validar = (props) => {
    const userId = Cookie.get("UserIdNoVal");
    const [input, handleInputChange] = useInputChange()

    const { codigo, contrasena } = input;
    const [invalid, setInvalid] = useState("");

    const { userAuth, setUserAuth } = useContext(userLoginContext);
  

    const handleSubmit = e => {
        e.preventDefault();
        setInvalid("");
        console.log(codigo);
        fetch("/validarCodigo/" + userId + "/" + codigo, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then((res) => {
            if (res.status === 200) {
                // Redireccionar luego de logearse
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: '¡Cuenta validada!',
                    showConfirmButton: false,
                    timer: 2500
                })
                props.history.push("/ingreso");
            }
            else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'El código ingresado es incorrecto',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        });
    }

    const resendToken = () => {
        fetch("/resendtoken", {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: userId
        }).then(response => {
            console.log(response.status);
            if (response.status === 200) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Te enviamos el nuevo código',
                    showConfirmButton: false,
                    timer: 2500
                })
            } else if (response.status === 400) {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Ocurrió un error al enviar el código',
                    showConfirmButton: false,
                    timer: 2000
                })
            };
        });
    }
   
    return (
        <div id="card" className="grow container shadow-lg p-3 my-4 vh-100 rounded">
            <h2 className="text-center font-weight-bold" style={{ color: "#064EB4" }}>¡Bienvenido!</h2>
            <br></br>
            <h4 className="text-center font-weight-bold">Enviamos un código de validación a tu correo. Ingresalo acá abajo para empezar: </h4>
            <br></br>
            <div className="form">
                <form action="" method="post" name="validar">
                    <div className="form-group">
                        <input type="codigo" name="codigo" className="form-control-val" id="codigo" placeholder="Código" onChange={handleInputChange} maxLength="4"/>
                    </div>                    
                    <div className="text-center">
                        <button className="btn-primary-val" onClick={handleSubmit}>Validar</button>
                        
                    </div>
                </form>
            </div>
            <br></br>
            <div className="reenviocodigo">
                <h5 className="text-center font-weight-bold">¿No recibiste el código? </h5>
                <button className="btn primary-line-button-val btn-block" onClick={resendToken}>Reenviar código</button>
                
            </div>
            <h6 className="text-center font-weight-lighter text-gris">Si no encontrás el código, podría estar en tu carpeta de SPAM </h6>
            
        </div>
    );
}
export default Validar;