import React, { useState } from "react";
import BusquedaPic from "../BusquedaPic/BusquedaPic";
import ImportImage from "../ImportImage/ImportImage";
import Plato from "../Plato/Plato";
import uid from "uid";
import NuevoIngrediente from "../NuevoIngrediente/NuevoIngrediente";
import BtnEliminar from "../BtnEliminar/BtnEliminar";
import { alertAdd } from '../../Alerts';
import $ from 'jquery';
import '../../styles/buttons.css';
import Instrucciones from "../Instrucciones/Instrucciones";
import Swal from "sweetalert2";
const maxCharNombre = 50;
const maxCharPalabra = 14;
const maxCharPrecio = 8;
const estilosPlato = {
    backgroundColor: "white",
    borderRadius: "10%",
};

const estiloInputPicto = {
    width: "80%",
    height: "100px",
    textAlign: "center",
    resize: "none"
}

const ModalCrearPlato = ({ seccion, add, monedaActual }) => {
    const estadoNuevoPlato = {
        idSeccion: seccion.idSeccion,
        idProducto: uid(),
        nombre: "",
        precio: "",
        srcPic: "",
        srcImg: "",
        ingredientes: [],
    };

    const [plato, setPlato] = useState(estadoNuevoPlato);

    const estadoIngrediente = {
        idIngrediente: uid(),
        idProducto: plato.idProducto,
        nombre: '',
        src: ''
    };
    const [ingrediente, setIngrediente] = useState(estadoIngrediente);

    const [isPlatoTab, setIsPlatoTab] = useState(true);

    const [srcImg, setSrcImg] = useState("");

    const [pictograms, setPictograms] = useState([]);

    const [parametroBusqueda, setParametroBusqueda] = useState("");

    const onChange = e => {
        setPlato({ ...plato, [e.target.name]: e.target.value });
    };

    const addIngrediente = (ingrediente) => {
        setPlato({ ...plato, ingredientes: [...plato.ingredientes, ingrediente] });
    };

    const deleteIngrediente = (idIngrediente) => {
        const ingredientesResult = plato.ingredientes.filter(ingrediente => ingrediente.idIngrediente != idIngrediente);
        setPlato({ ...plato, ingredientes: ingredientesResult });
    }

    const onSubmit = () => {
        if ((plato.srcPic === "" && plato.srcImg === "") || plato.nombre.trim() === "") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Para guardar el plato en tu men\u00FA, por favor asignale un nombre y adjunt\u00E1 un pictograma y/o foto",
                showConfirmButton: true,
            })
            return
        }
        if (plato.precio.length > maxCharPrecio) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "El precio no puede tener m\u00E1s de " + maxCharPrecio + " caracteres",
                showConfirmButton: true,
            })
            return
        }
        if (plato.nombre.length <= maxCharNombre) {
            let palabras = plato.nombre.split(' ')
            for (let i = 0; i < palabras.length; i++) {
                if (palabras[i].length > maxCharPalabra) {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "Las palabras ingresadas no deben exceder los " + maxCharPalabra + " caracteres",
                        showConfirmButton: true,
                    })
                    return
                }
            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: "El nombre del ingrediente no debe exceder los " + maxCharNombre + " caracteres",
                showConfirmButton: true,
            })
            return
        }
        add(plato);
        alertAdd("Producto agregado");
        reset();
    };


    const addPicto = (urlPicto) => {
        if (isPlatoTab) {
            addPictoProducto(urlPicto);
        }
        else {
            addPictoIngrediente(urlPicto)
        }
    }

    const addImg = (urlImg) => {
        setPlato({ ...plato, srcImg: urlImg });
    }

    const addPictoProducto = (urlPicto) => {
        setPlato({ ...plato, srcPic: urlPicto });
    }


    const addPictoIngrediente = (urlPicto) => {
        setIngrediente({ ...ingrediente, src: urlPicto });
    }

    const reset = () => {
        setPlato(estadoNuevoPlato);
        setSrcImg("")
        setPictograms([])
        setParametroBusqueda("")
        setIngrediente("")
        cleanIngrediente()
        $("#formBuscar")[0].reset();
    }

    const cleanProductPic = () => {
        setPlato({ ...plato, srcPic: "" });
    }
    const cleanProductImg = () => {
        setPlato({ ...plato, srcImg: "" });
    }

    const cleanIngrediente = () => {
        setIngrediente(estadoIngrediente);
    }

    return (
        <div
            className="modal fade"
            id={"modalCrearPlato-" + seccion.idSeccion}
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-labelledby="tituloCrearPlato"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header justify-content-center">
                        <Instrucciones />
                    </div>
                    <div className="modal-body py-0">
                        <div id="plato" style={estilosPlato}>
                            <div className="row">
                                <div className="col-4 p-0">
                                    {isPlatoTab ? <ImportImage addImg={addImg} srcImg={srcImg} setSrcImg={setSrcImg} /> : null}
                                    <BusquedaPic addpicto={addPicto} pictograms={pictograms} setPictograms={setPictograms} setParametroBusqueda={setParametroBusqueda} parametroBusqueda={parametroBusqueda} />
                                </div>
                                <div className="col-8">
                                    <div className="row d-flex justify-content-center">
                                        <h3 className="text-dark mb-4">{isPlatoTab ? "Plato" : "Ingrediente"}</h3>
                                        <div className="row d-flex col-12">
                                            <div className="col-12 col-lg-6">
                                                <button className="w-100 btn btn-light text-secondary mr-2" onClick={() => setIsPlatoTab(true)}>Plato</button>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <button className="w-100 btn btn-light text-secondary" onClick={() => setIsPlatoTab(false)} >Ingrediente</button>
                                            </div>
                                        </div>
                                        {isPlatoTab ? <div className="col-12 p-2 ">
                                            <form onSubmit={onSubmit} className="text-center">
                                                <div className="row justify-content-center">
                                                    <div className="col-sm-12 col-md-8">
                                                        <div className="form-group">
                                                            {plato.srcPic === "" ? <textarea
                                                                type="text"
                                                                name="src"
                                                                className="form-control"
                                                                placeholder="(Pictograma del plato)"
                                                                style={estiloInputPicto}
                                                            /> : <div className="text-center">
                                                                <BtnEliminar click={() => cleanProductPic()} />
                                                                <img
                                                                    src={plato.srcPic}
                                                                    alt="Img plato"
                                                                    width="156"
                                                                    height="156"
                                                                    style={estilosPlato}
                                                                />
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-8">
                                                        <div className="form-group">
                                                            {plato.srcImg === "" ? <textarea
                                                                type="text"
                                                                name="src"
                                                                className="form-control"
                                                                placeholder="(Imagen del plato)"
                                                                style={estiloInputPicto}
                                                            /> : <div className="text-center">
                                                                <BtnEliminar click={() => cleanProductImg()} />
                                                                <img
                                                                    src={plato.srcImg}
                                                                    alt="Img plato"
                                                                    width="156"
                                                                    height="156"
                                                                    style={estilosPlato}
                                                                />
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-8">
                                                    </div>
                                                    <div className="col-sm-12 col-md-8">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                name="nombre"
                                                                maxLength={maxCharNombre}
                                                                className="form-control text-dark"
                                                                placeholder="Nombre"
                                                                onChange={onChange}
                                                                value={plato.nombre}
                                                                style={{ textAlign: "center" }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                name="precio"
                                                                className="form-control text-dark"
                                                                placeholder={monedaActual + "0.00"}
                                                                maxLength={maxCharPrecio}
                                                                onChange={onChange}
                                                                value={plato.precio}
                                                                style={{ textAlign: "center" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div> : <div className="col-12 p-2">
                                            <NuevoIngrediente
                                                plato={plato}
                                                estadoIngrediente={estadoIngrediente}
                                                setIngrediente={setIngrediente}
                                                ingrediente={ingrediente}
                                                addIngrediente={addIngrediente}
                                                canAddAnother={plato.ingredientes.length < 8}
                                                cleanIngrediente={cleanIngrediente}
                                            />
                                        </div>}
                                        <hr />
                                        <div className="col-12">
                                            <hr />
                                            <h4 className="text-center text-dark">Vista previa</h4>
                                            <Plato isModal={true} producto={plato} btnEliminar={true} deleteIngrediente={deleteIngrediente} btnEliminarIngrediente={true} monedaActual={monedaActual} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer pr-4 justify-content-center">
                        <button
                            className="primary-line-button mt-2"
                            type="button"
                            data-dismiss="modal"
                            onClick={reset}
                        >
                            Cancelar
                        </button>
                        <button type="button"
                            data-dismiss="modal"
                            className="primary-button mt-2" onClick={onSubmit}>
                            Listo!
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCrearPlato;
