import React, { useState, useContext } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import fav from '../Resources/logo-salidas.png';
import userLoginContext from '../userContext';
import './NavMenu.css';
import '../../styles/buttons.css';
import Swal from 'sweetalert2';

const NavMenu = () => {

    // Accedindo a hook ofrecido por el contexto
    const { userAuth, setUserAuth } = useContext(userLoginContext);

    const [collapsed, setCollapsed] = useState(true);

  const cerrarSesion = () => {
    Swal.fire({
      title: '<strong>¿Realmente desea cerrar sesión?</strong>',
      icon: 'info',
      showCancelButton: true,
      focusConfirm: false,
      cancelButtonText:'No',
      confirmButtonText: 'Si',
        customClass: {
            confirmButton: 'primary-button',
            cancelButton: 'primary-line-button'
        }
      }).then((result) => {
        if (result.value) {
          return setUserAuth(false),
          Cookies.remove('SSID')
      }
      })
  }

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white  mb-3" light>
                <Container>
                    <NavbarBrand tag={Link} to="/" style={{ height: "88.8px" }}>
                        <img src={fav} className="Ico grow" alt="Salidas Inclusivas" data-html2canvas-ignore />
                        <span className="text3">Salidas Inclusivas</span>
                    </NavbarBrand>
                    <NavbarToggler onClick={() => setCollapsed(collapsed => !collapsed)} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">

                            {(userAuth == false) ?
                                // La segunda lista solo se muestra si el usuario está autenticado
                                <React.Fragment>
                                    <NavItem>
                                        <NavLink tag={Link} className="nav-item-primary-line" to="/ingreso">Ingresar</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="nav-item-primary" to="/registro">Registrarse</NavLink>
                                    </NavItem>
                                </React.Fragment>
                                : // else
                                <React.Fragment>
                                    <NavItem>
                                        <NavLink tag={Link} to="/" onClick={cerrarSesion} className="nav-item-primary-line">Cerrar sesión</NavLink>
                                    </NavItem>
                                </React.Fragment>
                            }
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header >
    );
}
export default NavMenu;
