import React from 'react';
import BtnAgregar from '../BtnAgregar/BtnAgregar';

export const Pic = (props) => {

    const src = ("https://api.arasaac.org/api/pictograms/" + props.id);
    return (
        <div className="border-bottom border-secondary">
            <img
                width="150"
                height="150"
                id={props.id}
                src={src}
                alt="Cargando..."
            />
            <BtnAgregar click={()=> props.addpicto(src)}></BtnAgregar>
                </div>
        )
}
