import React, { Fragment, useContext } from "react";
import uid from 'uid';
import Seccion from "../Seccion/Seccion";
import imgARASAAC from "../Resources/ARASAAC.png"
import imgLogoSalidas from "../Resources/logo-salidas.png";
import { MenuContext } from '../Context/menuContext';
import BtnEliminar from "../BtnEliminar/BtnEliminar";
import Swal from 'sweetalert2';
import { alertAdd } from '../../Alerts';
import '../../styles/buttons.css';
import '../Menu/Menu.css';
import { usePageHeightContext } from "../Context/pageHeightContext";
import { PDF_PAGE_HEIGHT, PRODUCT_MIN_HEIGHT } from "../../constants"

const estilosPagina = {
    backgroundColor: "white",
    width: "21cm",
    height: "1250px",
    padding: "15px",
    marginBottom: "10px",
    boxShadow: "0 .25rem .75rem rgba(0, 0, 0, .4)",
    display: "flex",
    flexDirection: "column",
    position: "relative",
};

const footer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    bottom: "0",
    width: "100%"
}

const copyRight = {
    fontSize: "8px",
    textAlign: "center",

}


const Pagina = ({ pagina, monedaActual, remove }) => {

    const { secciones, addSeccion, removeSeccion, productos } = useContext(MenuContext);
    const paginaSecciones = secciones.filter(seccion => seccion.idPagina == pagina.idPagina);
    const { totalPageHeights } = usePageHeightContext();
    const addSection = () => {
        addSeccion({
            idSeccion: uid(),
            idPagina: pagina.idPagina,
            nombre: ""
        });

        alertAdd('Secci&oacute;n agregada');
    }
    const alertDelete = () => {
        Swal.fire({
            title: '&iquest;Deseas eliminar esta p&aacute;gina?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: '&iexcl;Si, eliminar!',
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'primary-button',
                cancelButton: 'primary-line-button',
            }
        }).then((result) => {
            if (result.value) {
                remove(pagina.idPagina);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'P&aacute;gina eliminada',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        })
    }
    let cantSeccionesConProductos = 0
    for (let i = 0; i < secciones.length; i++) {
        for (let j = 0; j < productos.length; j++) {
            if (productos[j].idSeccion === secciones[i].idSeccion) {
                cantSeccionesConProductos++
            }
        }
    }
    let isAddSectionVisible = false
    if (secciones.length === 0) {
        isAddSectionVisible = true
    } else {
        if ((secciones.length - cantSeccionesConProductos) === 0) {
            isAddSectionVisible = (PDF_PAGE_HEIGHT - totalPageHeights[pagina.idPagina]) > PRODUCT_MIN_HEIGHT || totalPageHeights[pagina.idPagina] === undefined
        } else {
            isAddSectionVisible = false
        }
    }
    return (
        <Fragment>
            <div id={"Pagina " + pagina.idPagina} style={estilosPagina}>
                <BtnEliminar click={alertDelete} customClass={"botonEliminar"} titleTooltip="pagina" />
                {paginaSecciones.map((seccion) => {
                    return <Seccion seccion={seccion} key={`${pagina.idPagina}-${seccion.idSeccion}`} id={`${pagina.idPagina}-${seccion.idSeccion}`} monedaActual={monedaActual} remove={removeSeccion} />;
                })}
                {isAddSectionVisible ?
                    <label className="add-button d-flex align-items-center justify-content-center" type="button" onClick={addSection} data-html2canvas-ignore> <div className="text-center"><i className="fas fa-plus" style={{ fontSize: "5em" }}></i><span className="d-block">Agregar secci&oacute;n</span></div></label>

                    : null}
                <div id="footer" style={footer} >
                    <div>
                        <img id="arasaac" src={imgARASAAC} width="50px" />
                        <span> | </span>
                        <img id="logosalidas" src={imgLogoSalidas} width="40px" />

                    </div>
                    <span><p className="p-0 m-0" style={copyRight}><strong>Autor Pictogramas:</strong> Sergio Palao. <strong>Procedencia:</strong> ARASAAC (http://arasaac.org)  <strong>Licencia:</strong> CC (BY-NC-SA) <strong>Propiedad:</strong> Gobierno de Aragon.</p></span>
                </div>
            </div>
        </Fragment>
    );
};

export default Pagina;
