import React from 'react';
import { Link } from 'react-router-dom';
import fotoSiete from '../Resources/foto-7.png';
import fotoOcho from '../Resources/foto-8.jpg';
import fotoNueve from '../Resources/foto-9.jpg';
import secuencia from './secuenciaDeAcciones.zip';

import './senializacionAcciones.css';

const SenializacionAcciones = (props) => {

    let downloadFile = () => {
        const element = document.createElement("a");
        element.href = secuencia;
        element.download = "Secuencia De Acciones.zip";
        element.click();
    }

    return (
        <>
            <div className='row d-flex align-items-center justify-content-center mt-4'>
                <div className='col-md-12'>
                    <p>Las secuencias ayudan a poder anticipar visualmente los pasos a seguir y entender qué hacer en cada sector, como, por ejemplo: ingresar a una sala, comprar en el kiosco, etc.</p>
                </div>
            </div>
            <div className='row d-flex align-items-center justify-content-center mt-4'>
                <div className='col-md-6 text-md-right text-xs-center'>
                    <img src={fotoSiete} alt="" className='img-card-1'/>
                </div>
                <div className='col-md-6 text-md-left text-xs-center'>
                    <img src={fotoOcho} alt="" className='mb-3 img-card'/>
                    <img src={fotoNueve} alt="" className='mt-3 img-card'/>
                </div>
            </div>
            <div className='row d-flex align-items-center justify-content-center mt-4'>
                <div className='col-md-12'>
                    <p>Todas las secuencias deben ir ordenadas de la misma manera. De izquierda a derecha, de arriba hacia abajo o una combinación de ambas cuando sean muchos pasos. <br />Es importante que no se obstruya la visibilidad de las señalizaciones, ya sea con personal y/o con objetos.</p>
                    <button className='btn btn-primary btn-card' onClick={downloadFile}>Descargar secuencias de acciones</button>
                </div>
            </div>
        </>
    )
    
}
export default SenializacionAcciones;
