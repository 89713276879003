import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../Resources/custom.css';
import '../../styles/buttons.css';
import Card from '../Card/Card';
import Arma from '../Resources/arma.svg';
import Funciones from '../Resources/funciones.svg';
import Conocenos from '../Resources/conocenos.svg';
import Donaciones from '../Resources/donacion.svg';
import fav from '../Resources/logo-salidas.png';
import Rayita from '../Resources/linea.png';
import fb from '../Resources/fbk.png';
import tw from '../Resources/twt.png';
import ig from '../Resources/ig.png';
import ConocenosFoto from '../Resources/conocenos-foto.png'
import disney from '../Resources/logo-disney.png';
import cinemark from '../Resources/logo-cinemark.png';
import carrefour from '../Resources/logo-carrefour.png';
import microsoft from '../Resources/logo-microsoft.png';
import laBanda from '../Resources/logo-banda.png';
import sikla from '../Resources/logo-sikla.png';
import shiftaLogo from '../Resources/logo-shifta-conocenos.png';
import unirrol from '../Resources/logo-unirrol.png';
import qbic from '../Resources/logo-qbic.png';
import lauritsen from '../Resources/logo-lauritsen.png';
import './home.css';




export class Home extends Component {
    constructor(props) {
        super(props);
        this.testRef = React.createRef();
    }
    scrollToElement = () => {
        this.testRef.current.scrollIntoView({ behavior: 'smooth' });
        console.log('scrollto');
    }


    static displayName = Home.name;

    render() {
        return (
            <main class="container-fluid-lm text-center container-home">
                <div class="container-fluid d-flex align-items-center justify-content-center flex-column container-portada mb-5" id="bg-estrellas">
                    <div class="row d-flex align-items-center justify-content-center">
                        <img src={fav} width={300} ></img>
                    </div>
                    <div class="row d-flex align-items-center justify-content-center m-sm-3 mb-md-5">
                        <div class="col-xs-12 col-md-2">
                            <img src={Rayita}></img>
                        </div>
                        <div class="col-xs-12 col-md-8">
                            <h3 class="font-weight-bold">Hacia una convivencia <br /> sin barreras</h3>
                        </div>
                        <div class="col-xs-12 col-md-2">
                            <img src={Rayita}></img>
                        </div>
                    </div>
                    {/* Card Desktop */}
                    <div class="row d-none d-md-flex align-items-center justify-content-center m-sm-3 mt-md-5">
                        <div class="col-xs-12 col-md-3 animate__fadeInLeft animate__animated d-flex align-items-center justify-content-center">
                            <Card svg={Arma} title={"Armá tu menú con pictogramas"} link={"/armatumenu"} width={"100"} height={"100"} animation={true} pagination={false}></Card>
                        </div>
                        <div class="col-xs-12 col-md-3 d-flex align-items-center justify-content-center">
                            <Card svg={Funciones} title={"Funciones distendidas"} link={"/funcionesdistendidas"} width={"100"} height={"100"} animation={true} pagination={false}>
                            </Card>
                        </div>
                        <div class="col-xs-12 col-md-3 animate__fadeInRight animate__animated d-flex align-items-center justify-content-center" onClick={() => this.scrollToElement()}>
                            <Card svg={Conocenos} title={"Conocenos un poco más"} link={""} width={"100"} height={"100"} animation={true} pagination={false}></Card>
                        </div>
                        <div class="col-xs-12 col-md-3 d-flex align-items-center justify-content-center" onClick={() => window.open("https://donaronline.org/salidas-inclusivas/ayudanos-a-ayudar", "_blank")}>
                            <Card svg={Donaciones} title={"\u3000\u3000Hacé tu donación\u3000\u3000"} link={""} width={"100"} height={"100"} animation={true} pagination={false}></Card>
                        </div>
                    </div>
                    {/* Card Mobile */}
                    <div id="carouselExampleControls" class="carousel slide d-md-none d-sm-flex align-items-center justify-content-center m-sm-3 mt-md-5" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="d-flex align-items-center justify-content-center mt-5" >
                                    <Card svg={Arma} title={"Armá tu menú con pictogramas"} link={"/armatumenu"} width={"100"} height={"100"} pagination={false} ></Card>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="d-flex align-items-center justify-content-center mt-5">
                                    <Card svg={Funciones} title={"Funciones distendidas"} link={"/funcionesdistendidas"} width={"100"} height={"100"} pagination={false}></Card>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="d-flex align-items-center justify-content-center mt-5" onClick={() => this.scrollToElement()}>
                                    <Card svg={Conocenos} title={"Conocenos un poco más"} link={""} width={"100"} height={"100"} pagination={false}></Card>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="d-flex align-items-center justify-content-center mt-5" onClick={() => window.open("https://donaronline.org/salidas-inclusivas/ayudanos-a-ayudar", "_blank")}>
                                    <Card svg={Donaciones} title={"\u3000\u3000Hacé tu donación\u3000\u3000"} link={""} width={"100"} height={"100"} pagination={false}></Card>
                                </div>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                    <div class="row d-flex align-items-center justify-content-center m-3 mt-md-5 partners" style={{ backgroundColor: 'white' }}>
                        <div class="col-12">
                            <p class="font-weight-bold mt-2">Nos acompañan</p>
                            <img src={shiftaLogo} height={24} alt="" />
                            <img src={disney} height={45} alt="" />
                            <img src={microsoft} height={29} alt="" />
                            <img src={cinemark} height={24} alt="" />
                            <img src={carrefour} height={31} alt="" />
                            <img src={laBanda} height={55} alt="" />
                            <img src={sikla} height={24} alt="" />
                        </div>
                        <div class="col-12 mb-2">
                            <img src={unirrol} height={24} alt="" />
                            <img src={qbic} height={24} alt="" />
                            <img src={lauritsen} height={24} alt="" />
                        </div>
                    </div>

                </div>


                <div class="container-fluid p-2" ref={this.testRef}>
                    <div class="row pb-0">
                        <div class="col-md-12">
                            <h3 class="title mt-5">Conocenos un poco más</h3>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-8 pb-5">
                            <img src={ConocenosFoto} alt="" id="imagenConocenos" />
                        </div>
                        <div class="col-10 text-left pb-5">
                            <p class="card-text pb-2">
                                Salidas Inclusivas surgió gracias a la iniciativa de cuatro amigas con el propósito de garantizar <strong>igualdad de oportunidades</strong> para personas neurodivergentes, sus familias y redes de apoyo, buscando que puedan acceder sin barreras a los mismos espacios que el resto de la sociedad, de manera que puedan disfrutar plenamente de sus derechos como ciudadanos.
                            </p>
                            <p class="card-text pb-2">
                                Nuestro compromiso consiste en trabajar en conjunto con personas <strong>neurodivergentes</strong>, (autismo, TDA-H, dislexia, dificultades en el procesamiento sensorial, trastornos de la comunicación, entre otros), así como con sus redes de apoyo y familias.
                            </p>
                            <p class="card-text pb-2">
                                Somos una asociación civil sin fines de lucro con una misión claramente definida: <strong>derribar las barreras que impiden que todas las personas disfruten de las experiencias cotidianas de igual manera</strong>. Nos enfocamos en colaborar con diversas organizaciones, empresas y entidades, tanto públicas como privadas, para crear entornos accesibles para todos. A través de nuestra experiencia profesional, brindamos el apoyo necesario para hacer realidad esta transformación.
                            </p>
                            <p class="card-text pb-2">
                                Trabajamos en el ámbito de la <strong>accesibilidad cognitiva</strong> (crear y/o adaptar entornos para que sean fáciles de comprender por todas las personas favoreciendo así su autonomía e independencia) y las <strong>adaptaciones sensoriales</strong> (crear y/o adaptar el entorno, a través de la reducción de estímulos sensoriales de manera que las experiencias puedan ser disfrutadas por todas las personas). Esto mismo lo trabajamos en línea con lo establecido en los artículos 2 y 9 de la Convención de los Derechos de las Personas con Discapacidad, que tiene carácter constitucional en Argentina.
                            </p>

                        </div>
                    </div>
                    <div class="row mb-5">
                        <h3 class="card-text col-8 mx-auto text-align-center pb-2">
                            <p class="text-align-center" style={{ color: 'black' }}><strong>Nuestro sueño es avanzar paso a paso, en comunidad y en colaboración con otros, hacia una convivencia sin barreras.</strong></p>
                        </h3>
                    </div>
                    <div class="row mb-5">
                        <div class="col">
                            <div class="col-10 mx-auto">
                                <div class="row container-fluid justify-content-center">
                                    <div class="col-12 col-lg-6 mb-lg-5 mb-2">
                                        <p class="text-justify border-left border-primary px-3 mx-2 d-inline-block">
                                            <strong>Sandra Fariña:</strong><br />
                                            Abogada con especialización en Derecho del Trabajo y Discapacidad.   Diplomada en Autismo. Presidenta Co- Fundadora Salidas Inclusivas ONG. Mamá de una niña con autismo.<br /><br />
                                            IG: <a style={{ color: '#064eb4' }} href="https://www.instagram.com/sandfarina/" target="_blank" alt="sandfarina"><strong> @sandfarina</strong></a>
                                        </p>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-lg-5 mb-2">
                                        <p class="text-justify border-left border-primary px-3 mx-2 d-inline-block">
                                            <strong>Gabriela Leoni Olivera:</strong><br />
                                            Lic. en Psicología. Especializada en autismo, familia y discapacidad. Co- Fundadora de Salidas Inclusivas ONG, Co - Directora Casa Abanico, Co - Directora de Programa FADI.<br /><br />
                                            IG: <a style={{ color: '#064eb4' }} href="https://www.instagram.com/gabrielaleoniolivera/" target="_blank" alt="gabrielaleoniolivera"><strong>@gabrielaleoniolivera</strong></a>
                                        </p>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-2">
                                        <p class="text-justify border-left border-primary px-3 mx-2 d-inline-block">
                                            <strong>Agustina Girard:</strong><br />
                                            Lic. en psicología. Especializada en autismo, familia y discapacidad. Co- Fundadora Salidas Inclusivas ONG, Co - Directora Casa Abanico, Co – directora de Programa FADI.<br /><br />
                                            IG: <a style={{ color: '#064eb4' }} href="https://www.instagram.com/girard.agustina/" target="_blank" alt="girard.agustina"><strong>@girard.agustina</strong></a>
                                        </p>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-2">
                                        <p class="text-justify border-left border-primary px-3 mx-2 d-inline-block">
                                            <strong>Mariana A. Aleñá:</strong><br />
                                            Lic. en Fonoaudiología (UBA). Consultora de PECS Pyramid Educational Consultants Spain, Diplomada en Trastornos del Lenguaje Infantil desde una Perspectiva Neurolingüística por la Universidad Austral. Diplomada en Autismo y Síndrome de Asperger por la Universidad de Belgrano. Co- Fundadora Salidas Inclusivas ONG.<br /><br />
                                            IG: <a style={{ color: '#064eb4' }} href="https://www.instagram.com/lic.marianaalena/" target="_blank" alt="licmarianaalena"><strong>@licmarianaalena</strong></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <a href="https://twitter.com/sinclusivas" target="_new"><img src={tw} alt="Seguinos en Twitter" class="m-2" /></a>
                            <a href="https://www.facebook.com/salidas.inclusivas.7" target="_new"><img src={fb} alt="Seguinos en Facebook" class="m-2" /></a>
                            <a href="https://www.instagram.com/salidasinclusivas/" target="_new"><img src={ig} alt="Seguinos en Instagram" class="m-2" /></a>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
