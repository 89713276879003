import React, { Component } from 'react';
import './gracias.css';
import logo from '../Resources/funciones.svg';
import Card from '../Card/Card';
import Pasos from '../Pasos/Pasos';
import ScrollToTop from '../ScrollTop/ScrollTop';

export class Gracias extends Component {
    constructor(props) {
        super(props);
    }
    steps = [
        {number: 1, url: '/pasouno'},
        {number: 2, url: '/pasodos'},
        {number: 3, url: '/pasotres'},
        {number: 4, url: '/pasocuatro'},
        {number: 5, url: '/gracias'}
    ];

    render() {
        return (
            <main className="container-fluid text-center container-pasos">
                <ScrollToTop/>
                <Pasos>
                    <Card svg={logo} title={""} width={"100"} height={"100"} maxWidth={"700"} animation={false} pagination={true} current={this.steps[4]} pasos={this.steps}>
                        <div className="card-body-funciones">
                            <div className='row d-flex align-items-center justify-content-center'>
                                <div className='col-md-12'>
                                    <p>Esta propuesta de recursos y señalética para funciones de cine y teatro distendidas para personas neurodivergentes fue desarrollada por la ONG Salidas Inclusivas.</p>
                                    <p>La misma está enmarcada en una iniciativa colaborativa y regional cuyo objetivo es fomentar la oferta de funciones de este tipo en cada vez más cines y teatros de América Latina, y cuenta en esta primera instancia con el apoyo del equipo de Diversidad, equidad e inclusión de The Walt Disney Company Latin América, Cinemark Hoyts Argentina y Cinépolis México para su implementación.</p>
                                    <p>Esperamos que estos recursos sean útiles para inspirarte y acompañarte en el camino de realizar tus propias funciones distendidas/relajadas para que cada vez más personas puedan disfrutar de tus propuestas de entretenimiento.</p>
                                    <p>Los pictogramas fueron diseñados para poder ser utilizados en distintos espacios y para que puedas usarlos directamente o tomarlos como referencia pudiendo adaptarlos a tus necesidades.</p>
                                    <p>En caso que necesites más información, quieras compartir tus sugerencias, desees programar una capacitación para tu personal, asesoramiento personalizado o necesites pictogramas adicionales específicos para tu establecimiento, te invitamos a contactarte con nosotras escribiendo a salidasinclusivas@gmail.com para brindarte el apoyo que necesites.</p>
                                    <p style={{fontWeight: 'bold'}}>¡Gracias por sumarte a esta gran iniciativa!</p>
                                </div>
                            </div>
                        </div>                        
                    </Card>
                </Pasos>
            </main>
            

        );
    }
}
