import React, { Component } from "react";
import $ from 'jquery';
import '../../styles/buttons.css';
import InstruccionesRegistraTuComercio from "../InstruccionesRegistraTuComercio/InstruccionesRegistraTuComercio";
import InstruccionesEditaTuComercio from "../InstruccionesEditaTuComercio/InstruccionesEditaTuComercio";
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import validator from 'validator'
import BtnEliminar from "../BtnEliminar/BtnEliminar";
import uid from "uid";
import Resizer from "react-image-file-resizer";
import { Modal } from 'react-bootstrap';

const estilosFoto = {
    backgroundColor: "white",
    borderRadius: "20%",
};
const initState = {
    nombre: "",
    tipo: "",
    provincia: "",
    ciudad: "",
    direccion: "",
    webRedSocial: "",
    foto: "",
    activo: "",
    errorNombre: "",
    errorTipo: "",
    errorDireccion: "",
    errorRedSocial: "",
    errProvinciaCiudad: "",
    existeComercio: false,
};
let errorSinProductos = ""
let cantProductos;
let comercio;
let listaProvincias;
let listaTodasCiudades;
let listaCiudadesProvinciaSeleccionada;
const maxCharNombre = 80;
const maxCharDireccion = 150;
const maxCharWebRedSocial = 150;
const minCharNombre = 3;
const minCharDireccion = 5;
const minCharWebRedSocial = 10;
const maxCharPalabra = 14;

//NO MODIFICAR ID'S DE TIPOS DE COMERCIO NI NOMBRE DE LOS TIPOS DE COMERCIO QUE YA EXISTEN, SOLO SE PUEDE AGREGAR NUEVOS TIPOS DE COMERCIO.
//SE PUEDE AGREGAR SIGUIENDO EL ORDEN ASCENDENTE DESDE EL ID DEL TIPO DE COMERCIO ANTERIOR AL TIPO DE COMERCIO CON ID 1000 HASTA EL 1000
const tiposComercio = [
    { id: 1, nombre: "Cafeter\u00EDa" },
    { id: 2, nombre: "Kiosco" },
    { id: 3, nombre: "Panader\u00EDa" },
    { id: 4, nombre: "Parrilla" },
    { id: 5, nombre: "Restaurante" },
    { id: 6, nombre: "Helader\u00EDa" },
    { id: 1000, nombre: "Otros" }
];
async function getComercio() {
    const userId = Cookie.get("UserId");
    const token = Cookie.get("SSID");
    return fetch(`/api/comercio/GetComercioByIdUsuario?userId=${userId}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': token
        },
    }).then(response => response.json()).then((data) => {
        $("#valNombre").hide();
        return data
    }).catch(err => {
    });
}

async function getProvincias() {
    return fetch(`https://apis.datos.gob.ar/georef/api/provincias?aplanar=true&campos=estandar&max=5000&formato=json`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }).then(response => response.json()).then((data) => {
        $("#valProvinciaCiudad").hide();
        return data;
    }).catch(err => {
        const errProvinciaCiudad = "Hubo un problema al obtener los datos de las provincias y ciudades";
        initState.errProvinciaCiudad = errProvinciaCiudad;
        $("#valProvinciaCiudad").show();
    });
}

async function getCiudades() {
    return fetch(`https://apis.datos.gob.ar/georef/api/departamentos?aplanar=true&campos=estandar&max=5000&formato=json`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }).then(response => response.json()).then((data) => {
        $("#valProvinciaCiudad").hide();
        return data
    }).catch(err => {
        const errProvinciaCiudad = "Hubo un problema al obtener los datos de las provincias y ciudades";
        initState.errProvinciaCiudad = errProvinciaCiudad;
        $("#valProvinciaCiudad").show();
    });
}

function setOptionsCiudades(idProvincia) {
    const depProvSel = listaTodasCiudades.filter(x => {
        const values = Object.values(x);
        if (values[4] === idProvincia) {
            return x;
        }
    });
    listaCiudadesProvinciaSeleccionada = depProvSel;
    $("option[class='optRemoveCiudad']").remove();
    for (let i = 0; i < depProvSel.length; i++) {
        $('#ciudad').append(`<option class = "optRemoveCiudad" key = ${depProvSel[i].id} value = "${depProvSel[i].id}" >${depProvSel[i].nombre}</option>`);
    }
}

function setOptionsProvincias() {
    $("option[class='optRemoveProv']").remove();
    for (let i = 0; i < listaProvincias.length; i++) {
        $('#provincia').append(`<option class = "optRemoveProv" key = ${listaProvincias[i].id} value = "${listaProvincias[i].id}" >${listaProvincias[i].nombre}</option>`);
    }
}

async function getCantidadProductos() {
    const token = Cookie.get("SSID");
    return fetch(`/api/menu/GetCantProductosByIdUsuario`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': token
        },
    }).then(response => response.json()).then((data) => {
        cantProductos = data
        return data
    }).catch(err => {
        cantProductos = 0;
        return 0;
    });
}
let inputId = `InputFile-${uid()}}`

export class ModalRegistrarTuComercio extends Component {
    static displayName = ModalRegistrarTuComercio.name;

    constructor(props) {
        super(props);
        this.state = initState;
        this.close = this.close.bind(this);
        this.manageImage = this.manageImage.bind(this);
        this.cleanFoto = this.cleanFoto.bind(this);
        this.editComercioActivo = this.editComercioActivo.bind(this);
    };

    editComercioActivo = e => {
        e.preventDefault();
        const token = Cookie.get("SSID");
        let estado = false;
        if (this.state.activo === true) {
            estado = false;
        } else {
            estado = true;
        }
        comercio.activo = estado
        fetch(`/api/comercio/EditComercioActivo`, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': token
            },
            body: JSON.stringify(comercio)
        }).then(response => {
            if (response.status === 200) {
                this.setState({ activo: estado });
                comercio.activo = estado;
                if (this.state.activo) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Tu comercio ha sido agregado al listado',
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Tu comercio ha sido eliminado del listado',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            } else if (response.status === 400) {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Ocurri\u00F3 un error',
                    showConfirmButton: false,
                    timer: 2000
                })
            };
        });
    }

    cleanFoto() {
        this.setState({ foto: "" });
        $('#errorFoto').text("");
    }

    async manageImage(e) {
        $('#errorFoto').text("");
        const errExtension = "La extensi\u00F3n de la imagen debe ser .jpg o .png"
        const errTamano = "La imagen no debe superar los 1,5 Mb."
        const errGeneral = "Hubo un error al intentar cargar la imagen."
        let pathImg = ""
        e.target.files[0] ? pathImg = e.target.files[0].name : pathImg = ""
        let ext = pathImg.slice(pathImg.lastIndexOf('.') + 1).toLowerCase();
        if (ext === "") {
            $('#errorFoto').text(errExtension)
            this.setState({ foto: "" });
            return
        }
        if (!(ext === "jpg" || ext === "png" || ext === "jpeg")) {
            $('#errorFoto').text(errExtension)
            this.setState({ foto: "" });
            return
        }
        if (e.target.files[0].size >= 1572864) {
            $('#errorFoto').text(errTamano)
            this.setState({ foto: "" });
            return
        }
        try {
            await Resizer.imageFileResizer(
                e.target.files[0],
                156,
                156,
                e.target.files[0].type,
                0,
                0,
                (uri) => {
                    var a = 1;
                    this.setState({ foto: uri });
                    $('#errorFoto').text("")
                },
                "base64",
            );
        } catch (err) {
            $('#errorFoto').text(errGeneral)
            this.setState({ foto: "" });
        }
    }

    async componentDidMount() {
        if (await getCantidadProductos() === 0) {
            $('#modalRegistro').hide();
            errorSinProductos = "Para registrar tu comercio, agreg\u00E1 un plato en tu men\u00FA y guardalo."
        } else {
            $('#modalRegistro').show();
            errorSinProductos = ""
        }
        comercio = await getComercio();
        if (comercio != null) {
            this.setState({ existeComercio: true });
        } else {
            this.setState({ existeComercio: false });
        }
        const listTempPro = await getProvincias();
        listaProvincias = listTempPro.provincias.sort(function (a, b) {
            if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
            if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
            return 0;
        });
        const listaTempCiu = await getCiudades();
        listaTodasCiudades = listaTempCiu.departamentos.sort(function (a, b) {
            if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
            if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
            return 0;
        });
        if (comercio != null) {
            listaCiudadesProvinciaSeleccionada = listaTodasCiudades.filter(x => {
                const values = Object.values(x);
                if (values[4] === comercio.provincia) {
                    return x;
                }
            });
            this.close(false);
        } else {
            setOptionsProvincias();
        }
    }

    close(clickBtn) {
        if (comercio != null) {
            setOptionsProvincias();
            setOptionsCiudades(comercio.provincia);
            this.setState({ nombre: comercio.nombre == null ? "" : comercio.nombre });
            this.setState({ tipo: comercio.tipo == null ? "" : comercio.tipo });
            this.setState({ provincia: comercio.provincia == null ? "" : comercio.provincia });
            this.setState({ ciudad: comercio.ciudad == null ? "" : comercio.ciudad });
            this.setState({ direccion: comercio.direccion == null ? "" : comercio.direccion });
            this.setState({ webRedSocial: comercio.webRedSocial == null ? "" : comercio.webRedSocial });
            this.setState({ foto: comercio.foto == null ? "" : comercio.foto });
            this.setState({ activo: (comercio.activo == null || comercio.activo === "") ? false : comercio.activo });
            this.setState({ errorNombre: "" });
            this.setState({ errorTipo: "" });
            this.setState({ errorDireccion: "" });
            this.setState({ errorWebRedSocial: "" });
            $("#buttonListo").prop("disabled", false);
        }
        if (clickBtn === true) {
            this.props.setShowModal(false);
        }
    }

    handleChange = e => {
        const { target: { name, value } } = e;
        this.setState({ [name]: value });
        if (e.target.name === "provincia") {
            setOptionsCiudades(e.target.value);
        }
    }

    handleKeyUp = e => {
        this.handleValidation();
    }

    handleValidation = () => {
        let { nombre, tipo, direccion, webRedSocial, errorNombre, errorTipo, errorDireccion, errorWebRedSocial } = this.state;
        errorNombre = "";
        errorTipo = "";
        errorDireccion = "";
        errorWebRedSocial = "";
        if (nombre.length >= maxCharPalabra) {
            let palabrasNombre = nombre.split(' ')
            for (let i = 0; i < palabrasNombre.length; i++) {
                if (palabrasNombre[i].length > maxCharPalabra) {
                    errorNombre = "Las palabras del nombre no deben exceder los " + maxCharPalabra + " caracteres";
                }
            }
            if (nombre.length < minCharNombre || nombre.length > maxCharNombre || nombre == "" || nombre == null) {
                if (errorNombre !== "") {
                    errorNombre = errorNombre + ", debe tener un m\u00EDnimo de " + minCharNombre + " y un m\u00E1ximo de " + maxCharNombre + " caracteres.";
                } else {
                    errorNombre = "El nombre debe tener un m\u00EDnimo de " + minCharNombre + " y un m\u00E1ximo de " + maxCharNombre + " caracteres.";
                }
            }
        }
        if (direccion.length >= maxCharPalabra) {
            let palabrasDireccion = direccion.split(' ')
            for (let i = 0; i < palabrasDireccion.length; i++) {
                if (palabrasDireccion[i].length > maxCharPalabra) {
                    errorDireccion = "Las palabras de la direcci\u00F3n no deben exceder los " + maxCharPalabra + " caracteres";
                }
            }
            if (direccion) {
                if (direccion.length < minCharDireccion || direccion.length > maxCharDireccion) {
                    if (errorDireccion !== "") {
                        errorDireccion = errorDireccion + ", debe tener un m\u00EDnimo de " + minCharDireccion + " y un m\u00E1ximo de " + maxCharDireccion + " caracteres.";
                    } else {
                        errorDireccion = "La direcci\u00F3n debe tener un m\u00EDnimo de " + minCharDireccion + " y un m\u00E1ximo de " + maxCharDireccion + " caracteres.";
                    }
                }
            }
        }
        if (tipo === "" || tipo === null) {
            if (errorTipo === "") {
                errorTipo = "Debe haber un tipo de comercio seleccionado.";
            }
        }
        if (webRedSocial && webRedSocial !== "Sin web o redes sociales") {
            if ((webRedSocial.length < minCharWebRedSocial || webRedSocial.length > maxCharWebRedSocial || !validator.isURL(webRedSocial)) && webRedSocial != null) {
                errorWebRedSocial = "La web o red social debe tener un m\u00EDnimo de " + minCharWebRedSocial + " y un m\u00E1ximo de " + maxCharWebRedSocial + " caracteres.";
            }
        }
        this.setState({ errorNombre, errorTipo, errorDireccion, errorWebRedSocial });
        if (errorNombre || errorTipo || errorDireccion || errorWebRedSocial) {
            $("#buttonListo").prop("disabled", true);
            return false;
        }
        $("#buttonListo").prop("disabled", false);
        return true;
    }

    handleSubmit = e => {
        e.preventDefault();
        const token = Cookie.get("SSID");
        const userId = Cookie.get("UserId");
        const validado = this.handleValidation();
        const comercioSaveEdit = {
            idUsuario: userId,
            nombre: this.state.nombre,
            tipo: this.state.tipo,
            provincia: this.state.provincia === "-Elige una provincia-" ? "" : this.state.provincia,
            ciudad: this.state.ciudad === "-Elige una ciudad-" ? "" : this.state.ciudad,
            direccion: this.state.direccion === "" ? "Sin direcci\u00F3n" : this.state.direccion,
            webRedSocial: this.state.webRedSocial === "" ? "Sin web o redes sociales" : this.state.webRedSocial,
            foto: this.state.foto,
            activo: this.state.activo
        }
        if (comercio == null) {
            if (validado === true) {
                fetch(`/api/comercio/SaveComercio`, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': token
                    },
                    body: JSON.stringify(comercioSaveEdit)
                }).then(response => {
                    if (response.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Comercio guardado',
                            showConfirmButton: false,
                            timer: 2000
                        })
                        comercio = comercioSaveEdit;
                        const direccion = comercioSaveEdit.direccion;
                        const webRedSocial = comercioSaveEdit.webRedSocial;
                        const activo = comercioSaveEdit.activo;
                        this.setState({ direccion });
                        this.setState({ webRedSocial });
                        this.setState({ existeComercio: true });
                        this.setState({ activo });
                    } else if (response.status === 400) {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Ocurri\u00F3 un error',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    };
                });
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Hay errores de validaci\u00F3n',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        } else {
            if (validado === true) {
                fetch(`/api/comercio/EditComercio`, {
                    method: "PUT",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': token
                    },
                    body: JSON.stringify(comercioSaveEdit)
                }).then(response => {
                    if (response.status === 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Comercio editado',
                            showConfirmButton: false,
                            timer: 2000
                        })
                        comercio = comercioSaveEdit;
                        const direccion = comercioSaveEdit.direccion;
                        const webRedSocial = comercioSaveEdit.webRedSocial;
                        const activo = comercioSaveEdit.activo;
                        this.setState({ direccion });
                        this.setState({ webRedSocial });
                        this.setState({ activo });
                    } else if (response.status === 400) {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Ocurri\u00F3 un error',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    };
                });
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Hay errores de validaci\u00F3n',
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        }
        this.props.setShowModal(false);
    }

    render() {
        return (
            <Modal
                show={this.props.showModal}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Body>
                    {cantProductos === 0 ?
                        <div>
                            <div className="modal-header justify-content-left">
                                <InstruccionesRegistraTuComercio />
                            </div>
                            <div className="modal-body py-0">
                                <div className="row">
                                    <div className="col-md-9 mx-auto">
                                        <br></br>
                                        <h3 className="text-dark mb-4" id="errorSinProductos">{errorSinProductos}</h3>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <div>
                            <div className="modal-header justify-content-left">
                                {this.state.existeComercio === false ?
                                    <InstruccionesRegistraTuComercio /> :
                                    <InstruccionesEditaTuComercio />
                                }
                            </div>
                            <div className="modal-body py-0">
                                <div className="row">
                                    <div className="col-6 col-lg-4 my-lg-auto mx-auto d-flex justify-content-center">
                                        <div className="row d-flex align-items-center justify-content-center text-left p-1" id="ImportImage" >
                                            <label for={inputId} style={{ cursor: "pointer" }}><i class="bi bi-paperclip"></i>Adjuntar una foto</label>
                                            <input id={inputId} type="file" accept="image/png, image/jpeg"
                                                style={{ display: "none", cursor: "pointer" }}
                                                onChange={(e) => this.manageImage(e)}
                                                onClick={(e) => e.target.value = ''} />
                                            <div>
                                                <div className="row d-flex align-items-center justify-content-center" id="imageImport">
                                                    <div key={uid()} id="divImagen">
                                                        {this.state.foto !== "" ?
                                                            <img width="120" height="120" id="aaaaaaa" src={this.state.foto} alt="Cargando..." style={estilosFoto} />
                                                            :
                                                            <img
                                                                src="https://placehold.co/156x156?text=Sin Logo"
                                                                alt="Sin logo comercio"
                                                                width="120"
                                                                height="120"
                                                                style={estilosFoto}
                                                            />}
                                                        <BtnEliminar click={this.cleanFoto} />
                                                    </div>
                                                    <span id="errorFoto" className="d-block text-danger mt-1 text-center w-75"></span>
                                                </div>
                                                <button type="button"
                                                    className={(this.state.activo === false || this.state.activo === null || this.state.activo === "") ? "primary-button mt-2" : "primary-line-button mt-2"} onClick={this.editComercioActivo}>
                                                    {(this.state.activo === false || this.state.activo === null || this.state.activo === "") ? "Activar mi registro" : "Eliminar mi registro"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-8 mx-auto">
                                        <div className="form">
                                            <form action="" method="post" name="registroDeComercio" id="registroDeComercio">
                                                <div className="form-group w-100 mt-2">
                                                    <input type="text" name="nombre" className="form-control" id="nombre" placeholder="Nombre del comercio" value={this.state.nombre ? this.state.nombre : null} onChange={this.handleChange} onKeyUp={this.handleKeyUp} />
                                                    <span id="valNombre" className="d-block text-center text-danger mt-1" >{this.state.errorNombre}</span>
                                                </div>
                                                <div className="form-group w-100 mt-2">
                                                    <select name="tipo" className="custom-select" placeholder="Tipo de comercio" id="tipo" value={this.state.tipo ? this.state.tipo : null} onChange={this.handleChange} onClick={this.handleKeyUp} >
                                                        <option value="0" selected disabled defaultValue>-Tipo de comercio-</option>
                                                        {tiposComercio.map(({ id, nombre }) => <option value={id}>{nombre}</option>)}
                                                    </select>
                                                    <span id="valComercio" className="d-block text-center text-danger mt-1" >{this.state.errorTipo}</span>
                                                </div>
                                                <div className="form-group w-100 mt-2">
                                                    <select name="provincia" className="custom-select" placeholder="Seleccione una Provincia" id="provincia" value={this.state?.provincia ? this.state.provincia : null} onChange={this.handleChange}>
                                                        <option defaultValue>-Elige una provincia-</option>
                                                    </select>
                                                    <span id="valProvinciaCiudad" className="d-block text-center text-danger mt-1" >{this.state.errProvinciaCiudad}</span>
                                                </div>
                                                <div className="form-group w-100 mt-2">
                                                    <select name="ciudad" className="custom-select" placeholder="Seleccione una Ciudad" id="ciudad" value={this.state?.ciudad ? this.state.ciudad : null} onChange={this.handleChange} >
                                                        <option defaultValue>-Elige una ciudad-</option>
                                                    </select>
                                                </div>
                                                <div className="form-group w-100 mt-2">
                                                    <input type="text" name="direccion" className="form-control" placeholder="Direcci&oacute;n" id="direccion" value={this.state.direccion} onChange={this.handleChange} onKeyUp={this.handleKeyUp} />
                                                    <span id="valDireccion" className="d-block text-center text-danger mt-1" >{this.state.errorDireccion}</span>
                                                </div>
                                                <div className="form-group w-100 mt-2">
                                                    <input type="text" name="webRedSocial" className="form-control" placeholder="Web / Red Social" id="webRedSocial" value={this.state.webRedSocial} onChange={this.handleChange} onKeyUp={this.handleKeyUp} />
                                                    <span id="valWebRedSocial" className="d-block text-center text-danger mt-1" >{this.state.errorWebRedSocial}</span>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer className="modal-footer pr-4 justify-content-center">
                    <button
                        className="primary-line-button mt-2"
                        type="button"
                        onClick={() => this.close(true)}
                    >
                        Cancelar
                    </button>
                    {cantProductos > 0 ? <button type="button"
                        className="primary-button mt-2" onClick={this.handleSubmit}
                        id="buttonListo">
                        Listo!
                    </button>
                        : null}
                </Modal.Footer>
            </Modal >
        );
    }
};