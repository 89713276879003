import React, { Component } from 'react';
import './pasos.css';
import fav from '../Resources/logo-salidas.png';
import { Link } from 'react-router-dom';
import './pasos.css';
import back from '../Resources/back.png';

const Pasos = (props) => {
    return (
    <main class="container-fluid mb-5"     >
        <Link to="/" class="row d-flex align-items-center justify-content-center">
            <img src={fav} width={200} ></img>
        </Link>
        <div class="row mt-5">
            <div class="col-md-12">
                <h3 class="title">Armá tu función distendida</h3>
            </div>
        </div>
        <div class="row mt-5 bg-container-funciones">
            <div class="col-md-12 ">
                <div class="d-flex align-items-start justify-content-center container-card-pasos">
                    {props.children}
                </div>
            </div>
        </div>
    </main>
    );
}
export default Pasos;
