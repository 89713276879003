import React, { useRef, useContext, useState, useEffect, useLayoutEffect } from "react";
import ModalCrearPlato from "../ModalCrearPlato/ModalCrearPlato";
import Plato from "../Plato/Plato";
import BtnEliminar from "../BtnEliminar/BtnEliminar";
import { MenuContext } from "../Context/menuContext";
import Swal from 'sweetalert2';
import '../../styles/buttons.css'
import '../Menu/Menu.css';
import './Seccion.css';
import { usePageHeightContext } from "../Context/pageHeightContext";
import { PRODUCT_MIN_HEIGHT,PDF_PAGE_HEIGHT } from "../../constants";

const estilosContenidoSeccion = {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "auto auto",
    gap: "0.2em",
}

const agregarProductoInvisible ={
        visibility: "hidden",
}


const Seccion = ({ seccion, monedaActual, remove,id }) => {
    const { productos, updateNombreSeccion, addProducto, removeProducto } = useContext(MenuContext);
    const { pageHeights,totalPageHeights,setPageHeight } = usePageHeightContext();  
    const seccionRef = useRef(null);

      useEffect(() => {
        let timeoutId = null;
        const observarCambiosEnAltura = (entries) => {
            for (const entry of entries) {
                const nuevaAltura = entry.contentRect.height;
                if (nuevaAltura !== pageHeights[seccion.idPagina]?.[seccion.idSeccion]) {
                console.log(nuevaAltura,pageHeights[seccion.idPagina]?.[seccion.idSeccion])
                if (timeoutId !== null) {
                    clearTimeout(timeoutId);
                }
                // setTimeout se usa para evitar el error:  "ResizeObserver loop completed with undelivered notifications"
                timeoutId = setTimeout(() => {
                    setPageHeight(seccion.idPagina, seccion.idSeccion, nuevaAltura);
                }, 100);
                }
            }
        };
        const observer = new ResizeObserver(observarCambiosEnAltura);
        if (seccionRef.current) {
            observer.observe(seccionRef.current);
        }
        return () => {
            observer.disconnect();
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
        };
    }, [setPageHeight, seccion.idPagina]);  

    const seccionProductos = productos.filter(producto => producto.idSeccion == seccion.idSeccion);

    const addPlato = (producto) => {
        addProducto(producto);
    };

    const removePlato = (idProducto) => {
        removeProducto(idProducto);
    }

    const [ignoreName, setIgnoreName] = useState(!seccion.nombre);

    const updateNombre = (event) => {      
        if ((event.target.value != null) && (event.target.value != " ")) {
            updateNombreSeccion(seccion.idSeccion, event.target.value);
        }        
        setIgnoreName(!event.target.value);
    };

    const alertDelete = () => {
        Swal.fire({
            title: "&iquest;Deseas eliminar esta secci&oacute;n?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "&iexcl;Si, eliminar!",
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'primary-button',
                cancelButton: 'primary-line-button'
            }
        }).then((result) => {
            if (result.value) {
                setPageHeight(seccion.idPagina, seccion.idSeccion, 0)
                remove(seccion.idSeccion);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Secci&oacute;n eliminada',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }
    const isAddProductVisible = (PDF_PAGE_HEIGHT - totalPageHeights[seccion.idPagina] ) >PRODUCT_MIN_HEIGHT || seccionProductos.length%2 !== 0 
    return (
        <div>
        <div id={id} ref={seccionRef}>
            <div className="inputSeccion">
                <input className="estilosInput"
                        type="text"
                        name="nombre"
                        placeholder="Nombre de la secci&oacute;n"
                        value={seccion.nombre}
                        onChange={updateNombre}
                        {...ignoreName && { "data-html2canvas-ignore": true }}
                    />
                    <BtnEliminar click={alertDelete} customClass={"botonEliminarSeccion"} />
            </div> 
            <div className="estilosSeccion">                      
                <div style={estilosContenidoSeccion}>
                    
                    {seccionProductos.map((producto,indexPlato) => {
                        const isFirstRowElement = indexPlato === 0 || indexPlato ===2 || indexPlato%2 === 0                      
                        return <Plato
                            producto={producto}
                            key={`${seccion.idSeccion}-${producto.idProducto}`}
                            id={`${seccion.idSeccion}-${producto.idProducto}`}
                            remove={removePlato}
                            btnEliminar={false}
                            monedaActual={monedaActual}
                            isFirstRowElement={isFirstRowElement}
                            idPagina={seccion.idPagina}
                        />;
                    })}              
                     <div className="row p-2 m-2" style={isAddProductVisible?{}:agregarProductoInvisible} >
                    <button
                             className="add-button"
                        data-toggle="modal"
                         data-target={"#modalCrearPlato-" + seccion.idSeccion}
                        data-html2canvas-ignore
                             >
                        <i className="fas fa-plus" style={{ fontSize: "5em" }}></i><span className="d-block">Agregar plato</span>
                    </button>
                    </div>
                                    
                </div>
            </div>
        </div>
        <ModalCrearPlato
                        seccion={seccion}
                        add={addPlato}
                        monedaActual={monedaActual}>
                    </ModalCrearPlato>
                   
    </div>
    );
};

export default Seccion;
