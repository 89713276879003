import React, { useContext } from 'react';
import jsPDF from 'jspdf-yworks'
import html2canvas from '../../libs/html2canvas/html2canvas';
import './CreadorCartas.css';
import '../../styles/buttons.css';
import Menu from '../Menu/Menu';
import { MenuContext } from '../Context/menuContext';
import Swal from 'sweetalert2';

const CreadorCartas = () => {

    const { paginas } = useContext(MenuContext);


    const alertPDF = async () => {
        let removedElements = [];
        document.querySelectorAll('img').forEach(img => {
            
            if (!img.id.includes("modal") && img.src.includes("placehold.co")) {
                const parentElement = img.parentElement;
                const placeholderInfo = {
                    element: parentElement.cloneNode(true),
                    nextSibling: parentElement.nextSibling,
                    parentNode: parentElement.parentNode
                };
                removedElements.push(placeholderInfo);
                parentElement.remove();
            }
        });
        document.querySelectorAll("#footer").forEach((footer)=>{
            footer.style.position = "relative"
        }) 
        await newPDF();

        document.querySelectorAll("#footer").forEach((footer)=>{
            footer.style.position = "absolute"
        }) 

        removedElements.forEach(({ element, nextSibling, parentNode }) => {
            if (nextSibling && parentNode.contains(nextSibling)) {
                parentNode.insertBefore(element, nextSibling);
            } else {
                parentNode.appendChild(element);
            }
        });

        removedElements = [];
       
        Swal.fire({
            title: 'Generando PDF, espere un momento por favor...',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
           
        })
    }

    const alertPDFGenerado = () => {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Descargando...',
            showConfirmButton: false,
            timer: 2500
        })
    }
    function newPDF() {
        var y = [];
        var pdf = new jsPDF("p", "mm", "a4");
        paginas.map((pagina, index) =>
            y[index] = ("Pagina " + pagina.idPagina));
        console.log(y);
        renderPdf(y, pdf).then(() => {
            pdf.save('Menu.pdf');
        })
    }
    async function renderPdf(paginas, pdf) {    
        const canvasPromises = paginas.map(pagina =>
            html2canvas(document.getElementById(pagina),
                {
                    useCORS: true,
                    allowTaint: true,
                    scale: 2,
                    scrollY: -window.scrollY,
                    scrollX: -window.scrollX
                }).then((canvas) => canvas.toDataURL('image/jpeg', 1)));

        return Promise.all(canvasPromises).then(images => {
            images.forEach((image, index) => {
                const imgProps = pdf.getImageProperties(image);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(image, 'JPEG', 0, 0, pdfWidth, pdfHeight);

                if (index != paginas.length - 1) {
                    pdf.addPage();
                }
            });

            alertPDFGenerado();
        });
    }
    return (
        <Menu alertPDF={alertPDF} />
    )
}
export default CreadorCartas;
