import React, { Fragment, useContext, useState, useEffect } from "react";
import uid from 'uid';
import Pagina from "../Pagina/Pagina";
import { MenuContext } from '../Context/menuContext';
import MsjMenuVacio from '../MsjMenuVacio/MsjMenuVacio';
import $ from 'jquery';
import './Menu.css';
import '../../styles/buttons.css';
import { alertAdd } from '../../Alerts';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import FixedPanel from '../FixedPanel/FixedPanel';
import ConfigPanel from '../ConfigPanel/ConfigPanel';
import { ModalRegistrarTuComercio } from "../ModalRegistrarTuComercio/ModalRegistrarTuComercio";

const Menu = ({ alertPDF }) => {

    var Monedas = null;
    const [paises, setMoneda] = useState([]);
    const [monedaActual, setMonedaActual] = useState("");
    const [simboloActual, setSimboloActual] = useState("");
    const [showModal, setShowModal] = useState(false);
    const cambioConfigUsuario = (nuevaRegion) => {
        setMonedaActual(nuevaRegion);
        paises.forEach((elemento) => {
            if (elemento.nombre == nuevaRegion) {
                setSimboloActual(elemento.simbolo);
            }
        })
    }

    $(document).ready(function () {

        $('.ir-arriba').click(function () {
            $('body, html').animate({
                scrollTop: '0px'
            }, 150);
        });
        $(window).scroll(function () {
            if ($(this).scrollTop() > 1000
            ) {
                $('.ir-arriba').slideDown(300);
            } else {
                $('.ir-arriba').slideUp(100);
            }
        });
    });

    useEffect(() => {
        async function buscarMonedas(e) {
            const response = await fetch(`/getMonedas`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                },
                body: null
            });
            Monedas = await response.json();
            setMoneda(Monedas);;
            setMonedaActual(Monedas[0].nombre);
            setSimboloActual(Monedas[0].simbolo);
        }
        buscarMonedas();
    }, [])

    const { menu, getMenuObj, paginas, addPagina, removePagina, getNewMenu } = useContext(MenuContext);

    const add = () => {
        addPagina({
            idPagina: uid(),
            idMenu: menu.idMenu
        });
        alertAdd('Página agregada');
    };

    var menuGuardar;

    function desactivarComercio() {
        const userId = Cookie.get("UserId");
        const token = Cookie.get("SSID");
        return fetch(`/api/comercio/GetComercioByIdUsuario?userId=${userId}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': token
            },
        }).then(response => response.json()).then((data) => {
            data.activo = false;
            fetch(`/api/comercio/EditComercioActivo`, {
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token
                },
                body: JSON.stringify(data)
            });
            fetch(`/api/menu/DeleteMenuByIdUsuario`, {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': token,
                }
            });
        }).catch(err => {
        });
    }

    const removePage = (idPagina) => {
        removePagina(idPagina);
        if (paginas.length === 1) {
            desactivarComercio();
        }
    }

    const paginasMenu = paginas.filter(pagina => pagina.idMenu == menu.idMenu);

    const clearIds = () => {
        menuGuardar = getMenuObj();
        menuGuardar.idMenu = 0;
        menuGuardar.paginas.map((pagina) => {
            pagina.idMenu = 0;
            pagina.idPagina = 0
        });
        menuGuardar.paginas.map(pagina => pagina.secciones.map(seccion => seccion.idSeccion = 0));
        menuGuardar.paginas.map(pagina => pagina.secciones.map(seccion => seccion.idPagina = 0));
        menuGuardar.paginas.map(pagina => pagina.secciones.map(seccion => seccion.productos.map(producto => producto.idProducto = 0)));
        menuGuardar.paginas.map(pagina => pagina.secciones.map(seccion => seccion.productos.map(producto => producto.idSeccion = 0)));
        menuGuardar.paginas.map(pagina => pagina.secciones.map(seccion => seccion.productos.map(producto => producto.ingredientes.map(ingrediente => ingrediente.idIngrediente = 0))));
        menuGuardar.paginas.map(pagina => pagina.secciones.map(seccion => seccion.productos.map(producto => producto.ingredientes.map(ingrediente => ingrediente.idProducto = 0))));
    };

    function guardarMenu(e) {
        clearIds();
        const token = Cookie.get("SSID");
        const userId = Cookie.get("UserId");

        fetch(`/api/menu/SaveMenu`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': token
            },
            body: JSON.stringify({ Nombre: menuGuardar.nombre, Paginas: menuGuardar.paginas, idUsuario: userId })
        }).then(response => {
            if (response.status === 200) {
                borrarOldMenus();
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Guardado',
                    showConfirmButton: false,
                    timer: 1500
                })
            } else if (response.status === 400) {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Ocurrió un error',
                    showConfirmButton: false,
                    timer: 1500
                })
            };
        });
    }

    function borrarOldMenus() {
        const token = Cookie.get("SSID");

        fetch(`/api/menu/DeleteOldMenusByIdUsuario`, {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': token
            }
        }).then(response => {
            getNewMenu()
            return (response.status)
        });
    }

    const alertSave = () => {
        Swal.fire({
            title: '¿Deseas guardar este menú?',
            text: "",
            icon: 'question',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: '¡Si, guardar!',
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'primary-button',
                cancelButton: 'primary-line-button'
            }
        }).then((result) => {
            if (result.value) {
                guardarMenu();

            }
        })
    }
    return (
        <Fragment>
            <div id="menu" className="container mx-auto">
                <span className="ir-arriba" style={{ boxShadow: "0 .25rem .75rem rgba(0, 0, 0, .5)" }}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-up-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                    </svg>
                </span>
                <FixedPanel setShowModal={setShowModal} paginasMenu={paginasMenu} alertSave={alertSave} alertPDF={alertPDF} />
                <ConfigPanel paises={paises} onChange={cambioConfigUsuario} regionActual={monedaActual} />
                <div className="row justify-content-center pt-4">
                    {paginasMenu.length != 0 ? paginasMenu.map((pagina) => {
                        return <Pagina pagina={pagina} key={pagina.idPagina} monedaActual={simboloActual} remove={removePage} />;
                    }) : <MsjMenuVacio />}

                    <div className="col-12 d-flex justify-content-center">
                        {paginasMenu.length >= 10 ? null : <label
                            type="button"
                            id="newPaginaButton"
                            className="primary-line-button my-2"
                            onClick={add}
                        >
                            <i className="fas fa-plus mx-2"></i> Agregar página
                        </label>}
                    </div>
                </div>
            </div>
            {showModal === true ? <ModalRegistrarTuComercio showModal={showModal} setShowModal={setShowModal} /> : null}
        </Fragment>
    );
};

export default Menu;
