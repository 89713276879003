import React, { Component } from 'react';
import '../Resources/custom.css';
import './ArmaTuMenu.css';
import cocinero2 from '../Resources/cocinero-dsktp.png';
import icoInfinito from '../Resources/ico-infinito.svg';
import icoGratis from "../Resources/ico-gratis.svg";
import icoPdf from "../Resources/ico-pdf.svg";
import test1Gif from '../Resources/test1.52ffeb5b.gif';
import test2Gif from '../Resources/test2.39c5e8d5.gif';
import test3Gif from '../Resources/test3.0d082007.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../styles/buttons.css';
import { Link } from 'react-router-dom';
import shiftaLogo from '../Resources/logo-shifta.png';
import microsoft from '../Resources/logo-microsoft.png';
import Cookie from 'js-cookie';


const initState = {
    creadorRegistro: "",
    link: ""
};
export class ArmaTuMenu extends Component {
    static displatName = ArmaTuMenu.name;
    constructor(props) {
        super(props);
        this.state = initState;
    };

    componentDidMount() {
        if (Cookie.get("SSID") == null) {
            this.setState({ creadorRegistro: "REGISTRARSE" });
            this.setState({ link: "/registro" });
        } else {
            this.setState({ creadorRegistro: "Creador" });
            this.setState({ link: "/creador" });
        }
    }

    handleClick = () => this.props.history.push('registro');

    render() {
        return (
            <div class="container-fluid-lm text-center">
                <div class="container mbl">
                    <div class="col-12 px-0 animate__animated animate__bounceIn cocinero">
                        <img src={cocinero2} width="80%" alt=" Arma tu menu con pictogramas"></img>
                    </div>
                    <div class="p-3 p-md-2 mb-2 text-white encabezado">
                        <div class="col-md-6 px-0">
                            <h2 class="display-4 animate__animated animate__fadeInDown">¡Armá tu menú</h2>
                            <h1 class="display-4 animate__animated animate__fadeInUp">con  pictogramas!</h1>
                            <p class="lead my-3">Queremos ayudarte a convertir tu establecimiento en un espacio accesible y adaptado a las necesidades de tus clientes.</p>
                            <div className="row">
                                <Link class="btn btn-primary col-5" to={this.state.link} >{this.state.creadorRegistro}</Link>
                                <Link class="btn btn-outline-primary col-6 ml-2" to="/comerciosadheridos">Comercios adheridos</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container dsktp">
                    <div class="row encabezado">
                        <div class="col-md-5 px-0">
                            <h2 class="display-4 animate__animated animate__fadeInDown">¡Armá tu menú</h2>
                            <h1 class="display-4 animate__animated animate__fadeInUp">con  pictogramas!</h1>
                            <p class="lead my-3">Queremos ayudarte a convertir tu establecimiento en un espacio accesible y adaptado a las necesidades de tus clientes.</p>
                            <div className="row">
                                <Link class="btn btn-primary col-5" to={this.state.link} >{this.state.creadorRegistro}</Link>
                                <Link class="btn btn-outline-primary col-6 ml-2" to="/comerciosadheridos">Comercios adheridos</Link>
                            </div>
                        </div>
                        <div class="col-7 px-0 animate__animated animate__fadeInRight cocinero micocinero">
                            <img src={cocinero2} alt=" Arma tu menu con pictogramas" />
                        </div>
                    </div>
                </div>

                <div class="container-fluid bg-frutas bg-frutas-estrellas p-5">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="title">¿Cómo te ayudamos?</h3>
                        </div>
                    </div>
                    <div class="row ayudamos mb-5">
                        <div class="col-md-4 animate__fadeInLeft animate__animated mb-5">
                            <div class="row g-0 rounded mb-4">
                                <div class="ico">
                                    <img src={icoInfinito} alt="Ponemos a tu disposición una gran cantidad de pictogramas" class="ico" />
                                </div>
                                <div class="col p-5 d-flex flex-column pt-5">
                                    <p class="card-text mb-auto pt-3">Ponemos a tu disposición una gran cantidad de pictogramas</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <div class="row g-0 rounded mb-4 animate__fadeIn animate__animated">
                                <div class="ico">
                                    <img src={icoPdf} alt="Ponemos a tu disposición una gran cantidad de pictogramas" class="ico" />
                                </div>
                                <div class="col p-5 d-flex flex-column pt-5">
                                    <p class="card-text mb-auto pt-3">Podés exportar tu menú a PDF para poder imprimirlo</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <div class="row g-0 rounded mb-4 animate__fadeInRight animate__animated">
                                <div class="ico">
                                    <img src={icoGratis} alt="Ponemos a tu disposición una gran cantidad de pictogramas" class="ico" />
                                </div>
                                <div class="col p-5 d-flex flex-column pt-5">
                                    <p class="card-text mb-auto pt-3">Es gratis y no contiene publicidad</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid p-2 mt-5">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="title mt-5">El Proyecto</h3>
                        </div>
                    </div>
                    <div class="row ayudamos m-3">
                        <div class="col-md-6">

                            <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                    <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                                    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                                </ol>
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src={test1Gif} class="d-block w-100" alt="Creá tu menú personalizado con sus propios ingredientes"></img>
                                        <div class="carousel-caption d-md-block">
                                            <p>Creá tu menú personalizado con sus propios ingredientes</p>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <img src={test2Gif} class="d-block w-100" alt="Añadí hasta 8 productos por página"></img>
                                        <div class="carousel-caption  d-md-block">
                                            <p>Añadí hasta 8 productos por página</p>
                                        </div>
                                    </div>
                                    <div class="carousel-item">
                                        <img src={test3Gif} class="d-block w-100" alt="Guardá tu menú y modificalo cuando quieras"></img>
                                        <div class="carousel-caption  d-md-block">
                                            <p>Guardá tu menú y modificalo cuando quieras</p>
                                        </div>
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-6 p-5 pb-5 video">
                            <div class="row g-0 mb-2 pb-5">
                                <div class="col p-1 d-flex flex-column ">
                                    <p class="card-text mb-auto pt-1">
                                        Creamos una página cuyo objetivo es ofrecer accesibilidad cognitiva y apoyos visuales para mejorar la comprensión del entorno, así como fomentar la independencia de las personas con autismo en locales de comidas y bebidas.
                                    </p>
                                    <p class="card-text mb-auto pt-1">
                                        Este proyecto ha sido llevado a cabo gracias a la colaboración entre el equipo de Salidas Inclusivas, SHIFTA y Microsoft.
                                    </p>
                                    <p><img src={microsoft} height={29} alt="" /><img src={shiftaLogo} height={45} alt="" /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
