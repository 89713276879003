import React from 'react';
import { Link } from 'react-router-dom';
import foto10 from '../Resources/foto-10.png';
import foto11 from '../Resources/foto-11.png';
import video from './video.zip';

import './senializacionSala.css';

const SenializacionSala = (props) => {

    let downloadFile = () => {
        const element = document.createElement("a");
        element.href = video;
        element.download = "Video De Reloj.zip";
        element.click();
    }

    return (
        <>
            <div className='row d-flex align-items-center justify-content-center mt-4'>
                <div className='col-md-12'>
                    <p>Reloj/cronómetro en pantalla marcando tiempo de espera hasta que comience la función y reglas de la sala.</p>
                </div>
            </div>
            <div className='row d-flex align-items-center justify-content-center mt-4'>
                <div className='col-md-12'>
                <img src={foto10} alt="" className='m-md-2 card-img-1'/>
                    <img src={foto11} alt="" className='m-md-2 card-img-1'/>
                </div>
            </div>
            <div className='row d-flex align-items-center justify-content-center mt-4'>
                <div className='col-md-12'>
                    <button className='btn btn-primary btn-card' onClick={downloadFile}>Descargar video de reloj</button>
                </div>
            </div>
        </>
    )
}
export default SenializacionSala;
