import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import Layout from './components/Layout';
import { Home } from './components/Home/Home';
import { Registro } from './components/Registro/Registro';
import Validar from './components/Validar/Validar';
import Ingreso from './components/Ingreso/Ingreso';
import CreadorCartas from './components/CreadorCartas/CreadorCartas';
import Cookie from 'js-cookie';
import userLoginContext from './components/userContext';
import { RestablecerPassword } from './components/RestablecerPassword/RestablecerPassword';
import MenuContext from './components/Context/menuContext';
import ReactGA from 'react-ga';
import { useLocation, } from 'react-router-dom';
import { ArmaTuMenu } from './components/ArmaTuMenu/ArmaTuMenu';
import { FuncionesDistendidas } from './components/FuncionesDistendidas/FuncionesDistendidas';
import { StepOne } from './components/StepOne/StepOne';
import { StepTwo } from './components/StepTwo/StepTwo';
import { StepThree } from './components/StepThree/StepThree';
import { StepFour } from './components/StepFour/StepFour';
import { Gracias } from './components/Gracias/Gracias';
import { ComerciosAdheridos } from './components/ComerciosAdheridos/ComerciosAdheridos';
import { PageHeightProvider } from './components/Context/pageHeightContext';

// Bloquear acceso a componentes si no se está identificado
// Si buscan PrivateRoute van a encontrar este mismo ejemplo
// en todos lados

ReactGA.initialize('UA-207268755-1', { gaOptions: { siteSpeedSampleRate: 100 } });

const App = () => {
    const [userAuth, setUserAuth] = useState(
        (Cookie.get("SSID") != null) ? true : false
    );

    const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} component={(props) => (
            (userAuth === true)
                ? <Component {...props} />
                : <Redirect to='/ingreso' />
        )} />
    );

    const location = useLocation();
    const activeRoute = location.pathname;
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return (
        <userLoginContext.Provider value={{ userAuth, setUserAuth }}>
            <Layout ruta={activeRoute}>
                <Route exact path='/' component={Home} />
                <Route path='/registro' component={Registro} />
                <Route path='/armatumenu' component={ArmaTuMenu} />
                <Route path='/funcionesdistendidas' component={FuncionesDistendidas} />
                <Route path='/pasouno' component={StepOne} />
                <Route path='/pasodos' component={StepTwo} />
                <Route path='/pasotres' component={StepThree} />
                <Route path='/pasocuatro' component={StepFour} />
                <Route path='/gracias' component={Gracias} />
                <Route path='/ingreso' component={Ingreso} />
                <Route path='/validar' component={Validar} />
                <Route path='/forgotpassword' component={RestablecerPassword} />
                <Route path='/comerciosadheridos' component={ComerciosAdheridos} />
                {userAuth ?
                    <PageHeightProvider>
                        <MenuContext>
                            <PrivateRoute path='/creador' component={CreadorCartas} />
                        </MenuContext>
                    </PageHeightProvider>
                    
                     :
                    <Redirect to='/' />
                }
                {}
            </Layout >
        </userLoginContext.Provider>
    );
}

export default App;
