import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu/NavMenu';
import Footer from './Footer/Footer';
import Ingreso from './Ingreso/Ingreso';

const Layout = (props) => {
  console.log(props.ruta);
  const home = props.ruta === "/";
  const funciones = props.ruta === "/funcionesdistendidas";
  const pasouno = props.ruta === "/pasouno";
  const pasodos = props.ruta === "/pasodos";
  const pasotres = props.ruta === "/pasotres";
  const pasocuatro = props.ruta === "/pasocuatro";
  const gracias = props.ruta === "/gracias";
  const armatumenu = props.ruta === "/armatumenu";
  const registro = props.ruta === "/registro";
  const ingreso = props.ruta === "/ingreso";
  const validar = props.ruta === "/validar";
  const forgotpassword = props.ruta === "/forgotpassword";
    return (
      <div>
        {!home && !funciones && !pasouno && !pasodos && !pasotres && !pasocuatro && !gracias
        ? <NavMenu/>:""}
        {props.children}
        {armatumenu || registro || ingreso || validar || forgotpassword 
        ? <Footer showLeyend={true}/>:<Footer showLeyend={false}/>}
      </div>
    );
}
export default Layout;