import React, { Component } from 'react';
import logo from '../Resources/4to.svg';
import foto1 from '../Resources/Paso4-1.png';
import foto2 from '../Resources/Paso4-2.png';
import './stepFour.css';
import Card from '../Card/Card';
import Pasos from '../Pasos/Pasos';
import pdf from '../Resources/Anticipacion.pdf';
import ppt from '../Resources/Anticipacion.pptx';
import ScrollToTop from '../ScrollTop/ScrollTop';

export class StepFour extends Component {
    constructor(props) {
        super(props);
    }
    steps = [
        {number: 1, url: '/pasouno'},
        {number: 2, url: '/pasodos'},
        {number: 3, url: '/pasotres'},
        {number: 4, url: '/pasocuatro'},
        {number: 5, url: '/gracias'}
    ];

    downloadFilePDF = () => {
        const element = document.createElement("a");
        element.href = pdf;
        element.download = "Anticipación previa al comienzo de la película - modelo.pdf";
        element.click();
    }

    downloadFilePPT = () => {
        const element = document.createElement("a");
        element.href = ppt;
        element.download = "Anticipación previa al comienzo de la película - para completar.pptx";
        element.click();
    }

    render() {
        return (
            <main class="container-fluid text-center container-pasos">
                <ScrollToTop/>
                <Pasos>
                    <Card svg={logo} title={"Comunicación"} width={"100"} height={"100"} maxWidth="1400" animation={false} pagination={true} current={this.steps[3]} pasos={this.steps}>
                        <div class="card-body-funciones card-body-funciones-pasos">
                            <div className='row d-flex align-items-center justify-content-center'>
                                <div className='col-md-12'>
                                    <p>Es esencial brindar toda la Información posible con anterioridad con el fin de reducir la ansiedad y permitir la decisión autónoma del espectador de si considera que quiere o no asistir a una función.</p>
                                    <p>Para lograrlo recomendamos la utilización de un guion social. Una breve “guía” que muestra el paso a paso de la experiencia a través de fotos y pictogramas adaptados permitiendo al espectador conocer con anticipación qué se espera de él/ella en cada lugar por el que transitará. Esto debería estar disponible al momento de la venta y cada sala deberá desarrollar su propio guion social para reflejar las características de su espacio.</p>
                                </div>
                            </div>
                            <div className='row d-flex align-items-center justify-content-center'>
                                <div className='col-md-12'>
                                    <img src={foto1} alt="" width={400} className='m-md-5 m-xs-1'/>
                                    <img src={foto2} alt="" width={400} className='m-md-5 m-xs-1'/>
                                </div>
                            </div>
                            {/* <div className='row d-flex align-items-center justify-content-center'>
                                <div className='col-md-12'>
                                    <button className='btn btn-primary m-4' onClick={this.downloadFilePDF}>Descargar archivo modelo</button>
                                    <button className='btn btn-primary m-4' onClick={this.downloadFilePPT}>Descargar archivo editable</button>
                                </div>
                            </div> */}
                            <div className='row d-flex align-items-center justify-content-center '>
                                <div className='col-md-6 text-left'>
                                    <p>Recomendamos ofrecer información clara y fácilmente identificable sobre:
                                        <li>precios de boletos y descuentos disponibles</li>
                                        <li>canales de compra</li>
                                        <li>características de la función</li>
                                        <li>guías de anticipación visual</li>
                                        <li>señaléticas</li>
                                        <li>recursos descargables</li>
                                        <li>tiempo que dura la función</li>
                                        <li>tiempo que dura la función</li>
                                        <li>información acerca de donde se ubicará el/las áreas de regulación</li>
                                        <li>qué objetos están permitidos y cuáles no ingresar a la función</li>
                                    </p>
                                </div>
                                <div className='col-md-6 align-items-center justify-content-center'>
                                    <button className='btn btn-primary m-4' onClick={this.downloadFilePDF}>Descargar archivo modelo</button>
                                    <button className='btn btn-primary m-4' onClick={this.downloadFilePPT}>Descargar archivo editable</button>
                                </div>
                            </div>
                        </div>                        
                    </Card>
                </Pasos>
            </main>
            

        );
    }
}
