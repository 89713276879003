import React, { useState, useContext } from 'react';
import { useInputChange } from '../useInputChange'
import userLoginContext from '../userContext';
import '../Resources/custom.css';

const Ingreso = (props) => {

    // Delegar creacion de estado (onChange de los input)
    // a un archivo separado, para poder reutilizarlo
    // en otros formularios
    const [input, handleInputChange] = useInputChange()

    // Reemplazo de estado por hooks
    const { email, contrasena } = input;
    const [invalid, setInvalid] = useState("");
    const [secretQuestion, setSecretQuestion] = useState(0);
    const [respuestaSeguridad, setRespuestaSeguridad] = useState("none");

    // Accediendo a hook ofrecido por el contexto
    const { userAuth, setUserAuth } = useContext(userLoginContext);

    const handleSubmit = e => {
        e.preventDefault();
        setInvalid("");

        fetch("/login", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({ email, contrasena, secretQuestion, respuestaSeguridad })
        }).then((res) => {
            if (res.status === 200) {

                // Redireccionar luego de logearse
                setUserAuth(true);
                props.history.push("/creador");
            }
            else {
                if (res.status === 202) {
                    props.history.push("/validar");
                }
                else {
                    setInvalid("Datos de ingreso incorrectos");
                }
            }
        });
    }

    const invalidRedir = () => { props.history.push('/forgotpassword') };

    return (
        <div class="container-fluid container vh-100 text-center">
            <h3 class="title">Ingresar</h3>

            <div id="card" className="grow container  p-3 my-4">            
                <div className="row">
                    <div className="col-md-7 mx-auto">
                        <div className="form">
                            <form action="" method="post" name="registro">
                                <div className="form-group">
                                    <input type="email" name="email" className="form-control" id="email" placeholder="Email" onChange={handleInputChange} />
                                </div>
                                <div className="form-group">
                                    <input type="password" name="contrasena" className="form-control" id="contrasena" placeholder="Contraseña" onChange={handleInputChange} />
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary btn-block" onClick={handleSubmit}>Ingresar</button>
                                    <span className="d-block text-center text-danger mt-1">{invalid}</span>
                                    <button style={{ fontSize: "0.8em"}} className="bg-transparent border-0 text-dark" onClick={invalidRedir}>He olvidado mi contraseña.</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Ingreso;