import React from 'react';
import '../../styles/buttons.css'
import './FixedPanel.css'

const FixedPanel = ({ setShowModal, paginasMenu, alertSave, alertPDF }) => {

    return (
        <nav className="navbar sticky-top custom-sticky justify-content-center">
            <div className="d-flex flex-row flex-wrap justify-content-center">
                {paginasMenu.length > 0 ?
                    <button className="primary-button-small my-2" onClick={alertSave}>
                        <i className="far fa-save mx-2"></i>
                        Guardar menú
                    </button> :
                    null}
                <p className="primary-button-small my-2 d-flex flex-column align-items-center justify-content-center">
                    <div className="justify-content-center">
                        <i className="far fa-file-alt mx-2"></i>
                        <span>Páginas: {paginasMenu.length} / 10</span>
                    </div>
                    {paginasMenu.length >= 10 ? <p className="text-center p-0 pl-2 m-0">Límite alcanzado</p> : null}
                </p>

                {paginasMenu.length > 0 ?
                    <button type="button" className="primary-button-small my-2" onClick={alertPDF}>
                        <i className="fas fa-file-download mx-2"></i>
                        Exportar a PDF
                    </button> :
                    null}
                {paginasMenu.length > 0 ?
                    <button
                        className="primary-button-small my-2"
                       
                        data-html2canvas-ignore
                        onClick={() => setShowModal(true)}>
                        <i className="bi bi-geo-alt mx-2"></i>
                        Registrar tu comercio
                    </button> :
                    null}
            </div>
        </nav>
    )
}

export default FixedPanel;