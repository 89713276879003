import React, { Fragment, useState, useEffect } from 'react';
import '../../styles/buttons.css';
import './ConfigPanel.css';
import Select from 'react-select'

const ConfigPanel = ({ onChange, paises }) => {

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'black' : 'grey',
        }),
        control: (provided, state) => ({
            ...provided,
            color: "black"
        })
    }
    const options = paises.map(x => ({
        "value": x.idRegion,
        "label": x.nombre
    }))    

    function handleChange(e) {
        setSelectedOption(e);
        onChange(e.label);
    }

    const [selectedOption, setSelectedOption] = useState(null);
    var moneda = null;


    useEffect(() => {
        if (options.length != 0 && options != null && options != undefined && selectedOption == null) {
            setSelectedOption(options[0]);
        }
    }, [options])

    return (
        <nav className="navbar custom-sticky justify-content-center">
            <div className="d-flex flex-row flex-wrap justify-content-center">
                <p className="primary-button my-2 d-flex flex-column align-items-center justify-content-center">
                    <div className="justify-content-center">
                        <i className="far fa-flag mx-2"></i>
                        <span>Moneda:
                        </span>
                        <Select
                            value={selectedOption}
                            styles={customStyles}
                            onChange={handleChange}
                            options={options}
                        />
                    </div>
                </p>
            </div>
        </nav>
    )
}



export default ConfigPanel;